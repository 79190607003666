.stepBlock {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6vw;
  flex-direction: row-reverse;
}
.steps {
  background: #f2f4f8;
}
.stepBlock:nth-child(2n) {
  flex-direction: row;
}
.stepBlock > img {
  width: 33vw;
}
.stepsBody {
  width: 80vw;
  margin: 0 auto;
}
.stepTitle {
  color: #303030;
  margin-top: 2vw;
  font-family: "Coolvetica Rg";
  font-size: 3.7vw;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 1vw;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  margin-bottom: 1vw;
}
.poshlina {
  color: #fff;

  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  padding: 1vw;
  margin-top: -6vw;
  margin-bottom: 6vw;
  width: 100%;
  border-radius: 1vw;
  background: linear-gradient(90deg, #4bbaf8 -1.39%, #03d8a5 100%);
}
.prov {
  margin-top: 1vw;
  font-family: "Coolvetica Rg";
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.grs {
  font-family: "Coolvetica Rg";
  font-size: 2.2vw;
  font-style: normal;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.grs span {
  background: none;
  color: #5a6385;
  background-clip: unset;
  -webkit-text-fill-color: #5a6385;
}
.stt {
  flex-direction: row;
}
.stt:nth-child(2n) {
  flex-direction: row-reverse;
}
.stepBlock li {
  padding: 0.5vw 0;
  max-width: 42vw;
}
.stepBlock li img {
  width: 4vw;
  margin-right: 0.7vw;
  height: 4vw;
}
.stepBlock ul {
  margin-top: 1vw;
}
.stepBlock li p {
  color: #3e2e2e;

  font-family: "Montserrat";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 500;
}
.stepBlock ul > img {
  width: 2.9vw;
  margin-top: 0.3vw;
  margin-left: 0.6vw;
}
.stepsBody > h3 {
  color: #303030;

  font-family: "Coolvetica Rg";
  font-size: 5.2vw;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  padding-top: 4vw;
}
.button {
  position: relative;
  overflow-x: hidden;
}
.button .flare,
.sendFrom .flare {
  position: absolute;
  top: 0;
  margin-top: 0 !important;
  height: 100%;
  width: 45px;
  transform: skewX(-45deg);
  animation: flareAnimation;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 3s infinite linear;
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}
.stepsBody > p {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 49.73%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Coolvetica Rg";
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4vw;
}
.stepContent .button {
  padding: 2vw 5.5vw;
  margin-top: 3vw;
  font-size: 1.5vw;
}
.tline {
  width: 100%;
  border-bottom: 3px dashed rgba(139, 126, 194, 0.53);
  margin-bottom: 4vw;
}
@media screen and (max-width: 640px) {
  .stepsBody {
    width: 94vw;
    margin: 0 auto;
  }
  .stepsBody > h3 {
    color: #303030;
    font-family: "Coolvetica Rg";
    font-size: 17.2vw;
    font-style: normal;
    line-height: 17vw;
    font-weight: 400;
    text-transform: uppercase;
    padding-top: 17vw;
  }
  .stepsBody > p {
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 49.73%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Coolvetica Rg";
    font-size: 8.5vw;
    font-style: normal;
    margin-top: 3vw;
    font-weight: 400;
    margin-bottom: 13vw;
  }
  .tline {
    width: 100%;
    border-bottom: 3px dashed rgba(139, 126, 194, 0.53);
    margin-bottom: 15vw;
  }
  .stepContent .button {
    padding: 6.5vw 4.5vw;
    margin-top: 11vw;
    font-size: 6.2vw;
    margin-bottom: 5vw;
    width: 100%;
    text-align: center;
  }
  .stepBlock {
    flex-direction: column-reverse !important;
  }
  .stepTitle {
    color: #303030;
    margin-top: 2vw;
    font-family: "Coolvetica Rg";
    font-size: 9.6vw;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 3vw;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    margin-bottom: 5vw;
  }
  .stepBlock li p {
    color: #3e2e2e;
    font-family: "Montserrat";
    font-size: 4.2vw;
    font-style: normal;
    font-weight: 500;
  }
  .poshlina {
    color: #fff;
    text-align: center;
    font-family: "Coolvetica Rg";
    font-size: 6.9vw;
    font-style: normal;
    font-weight: 400;
    padding: 4vw;
    margin-top: -6vw;
    margin-bottom: 14vw;
    width: 100%;
    border-radius: 6vw;
    background: linear-gradient(90deg, #4bbaf8 -1.39%, #03d8a5 100%);
  }
  .grs {
    font-family: "Coolvetica Rg";
    font-size: 6.7vw;
    font-style: normal;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 3vw;
  }
  .stepBlock ul > img {
    width: 10.9vw;
    margin-top: 0.3vw;
  }
  .prov {
    margin-top: 3vw;
    font-family: "Coolvetica Rg";
    font-size: 6.7vw;
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .stepBlock > img {
    width: 100%;
    margin-top: 5vw;
  }
  .stepBlock li {
    padding: 1.5vw 0;
    max-width: unset;
  }
  .stepBlock li img {
    width: 10.7vw;
    margin-right: 3.5vw;
    height: auto;
  }
}
