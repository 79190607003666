.market_body {
  width: 80vw;
  margin: 0 auto;
  padding-bottom: 6vw;
}
.market {
  background: #f2f4f8;
}
ul {
  list-style-type: none;
}
ul li {
  display: flex;
  align-items: center;
}
.market_content {
  display: flex;
}
.marketForm {
  display: flex;
  margin-top: 4vw;
}
.circlescont {
  display: flex;
}
.special {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Coolvetica Rg";
  font-size: 3vw;

  font-weight: 400;
}
.market_body h3 {
  color: #303030;

  font-family: "Coolvetica Rg";
  font-size: 6.8vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 3vw;
  text-transform: uppercase;
}
.market_body h3 img {
  width: 6vw;
  position: relative;
  top: 0.75vw;
  left: -1vw;
}
.market_body li p {
  color: #4b4b4b;

  font-family: "Montserrat";
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.market_body li div {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Inter";
  font-size: 3.5vw;
  margin-right: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 2.5vw;
  text-align: center;
}
.marketArrow {
  margin-left: 16vw;
  margin-top: 1vw;
  width: 2vw;
}
.rightMarket img {
  width: 39vw;
  margin-left: 4vw;
}
.market_body li {
  padding: 0.7vw 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.market_body li:last-child {
  border-bottom: none !important;
}
.marketFormLeft {
  padding: 2vw;
  width: 31vw;
  border-radius: 3vw;
  background: #fff;
  display: flex;
  flex-direction: column;

  box-shadow: 0px 0px 60px 0px rgba(132, 145, 172, 0.39);
}
.marketFormLeft input {
  border-radius: 3vw;
  padding: 1.3vw 1.5vw;
  width: 100%;
  outline: none;
  border: none;
  background: #f1f6fa;
  font-size: 1.2vw;
  margin-bottom: 1.5vw;
}
.marketWorkLeft h2 {
  color: #303030;

  font-family: "Coolvetica Rg";
  font-size: 7vw;
  line-height: 7vw;
  font-style: normal;
  font-weight: 400;

  text-transform: uppercase;
}

.marketWorkLeft h2 img {
  width: 6.5vw;
  position: relative;
  top: 0.75vw;
  left: -1vw;
}
.marketFormLeft > p {
  color: #4b4b4b;
  text-align: center;
  font-family: "Montserrat";
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.marketFormLeft > span {
  color: rgba(131, 131, 172, 0.79);

  text-align: center;
  font-family: "Montserrat";
  font-size: 0.95vw;
  margin-top: 0.2vw;
  font-weight: 600;
  margin-bottom: 2vw;
}
.inputTittle {
  margin-bottom: 0.3vw;
  color: #4b4b4b;

  font-family: "Montserrat";
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 500;
}
.marketFormLeft .button {
  margin-top: 0.5vw;
  width: 100%;
  padding: 1.5vw 1vw;
  text-align: center;
  color: #fff;

  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 1.25vw;
  font-style: normal;
  font-weight: 400;
}
.agree {
  display: flex;
  align-items: center;
  margin-top: 1vw;
}
.agree img {
  width: 1.3vw;
  margin-right: 0.5vw;
}
.agree p {
  color: rgba(131, 131, 172, 0.79);
  font-family: "Montserrat";
  font-size: 0.65vw;
  font-style: normal;
  font-weight: 500;
}
.agree p span {
  text-decoration: underline;
  font-weight: 700;
}
.marketFormRight {
  margin-left: 4.5vw;
}
.marketFormRight > p {
  color: #303030;

  font-family: "Coolvetica Rg";
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 2vw;
}
.circlescont img {
  width: 1.15vw;
  margin-right: 0.5vw;
}
.circlescont {
  align-items: flex-start;
}
.rightCircles {
  margin-top: -1vw;
}
.rightCircles p {
  color: #3e2e2e;

  font-family: "Coolvetica Rg";
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
}
.rightCircles span {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat";
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 2.65vw;
  display: block;
}
.marketWork {
  margin-top: 4vw;
}
.marketWorkLeft p {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Coolvetica Rg";
  font-size: 2.8vw;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 3vw;
}
.marketWorkRight {
  padding: 1.5vw;
  border-radius: 2vw;
  color: #fff;
  background: linear-gradient(
    117deg,
    #25e3b6 2.61%,
    #00d0f9 48.39%,
    #5784f7 99.2%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 18vw;
  margin-top: 1vw;
}
.marketWorkRight p {
  color: #fff;
  margin-top: 0.5vw;
  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 6vw;
  font-style: normal;
  font-weight: 400;
  width: fit-content;
  border-bottom: 2px solid rgba(255, 255, 255, 0.31);
}
.marketWorkRight span {
  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 2vw;
  width: 14vw;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.5vw;
  margin-bottom: 1vw;
}
.marketWork {
  display: flex;
  align-items: flex-start;
}
.elemsMarket {
  display: flex;
  justify-content: space-between;
  margin-top: 2vw;
}
.marketElem {
  padding: 1.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1.5vw;
  width: 18.5vw;
  height: 16.5vw;
  background: radial-gradient(
      100.03% 100.02% at 48.08% 0%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(65, 65, 65, 0) 63.6%
    ),
    linear-gradient(145deg, #474e65 1.8%, #1f212b 53.75%, #282b36 87.75%);
  box-shadow: 0px 3.6036px 40px 0px rgba(53, 92, 129, 0.19);
}
.marketElem img {
  width: 3vw;
  margin-bottom: 2vw;
}
.marketElem p {
  color: #fff;

  font-family: "Coolvetica Rg";
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 400;
}
.situation {
  display: flex;
  margin-top: 2.5vw;
}
.marketElem span {
  color: rgba(212, 237, 255, 0.8);

  font-family: "Montserrat";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.7vw;
}
.situationEm {
  border-radius: 1.5vw;
  background: #fff;
  padding: 2vw 2.5vw;
  margin-left: 2.2vw;
  width: 59.5vw;
  box-shadow: 0px 0px 60px 0px rgba(147, 163, 194, 0.3);
}
.situationFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.situationEm > p {
  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 1vw;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2vw;
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.situationFlex p {
  color: #303030;

  font-family: "Coolvetica Rg";
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
  margin-right: 1vw;
}
.situationFlex .button {
  padding: 2vw 3.5vw;
  color: #fff;

  font-family: "Montserrat";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;
}
@media screen and (max-width: 640px) {
  .market_body {
    width: 94vw;
  }
  .marketArrow {
    margin-left: 10vw;
    margin-top: 5vw;
    width: 5vw;
  }
  .special {
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Coolvetica Rg";
    font-size: 6.5vw;
    font-weight: 400;
    max-width: 80vw;
  }
  .market_body h3 {
    color: #303030;
    font-family: "Coolvetica Rg";
    font-size: 10vw;
    font-style: normal;
    font-weight: 400;
    line-height: 10vw;
    margin-bottom: 3vw;
    text-transform: uppercase;
  }
  .market_body h3 img {
    width: 8vw;
    position: relative;
    top: 0.75vw;
    left: -1vw;
  }
  .market_body li {
    padding: 2.5vw 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .market_body li p {
    color: #4b4b4b;
    font-family: "Montserrat";
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .market_body li div {
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Inter";
    font-size: 7.6vw;
    margin-right: 2vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 5.5vw;
    text-align: center;
  }
  .marketFormRight {
    margin-left: 0vw;
  }
  .marketFormRight > p {
    color: #303030;
    font-family: "Coolvetica Rg";
    font-size: 13.7vw;
    margin-top: 12vw;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 11vw;
  }
  .rightCircles p {
    color: #3e2e2e;
    font-family: "Coolvetica Rg";
    font-size: 9.3vw;
    font-style: normal;
    font-weight: 400;
  }
  .rightCircles {
    margin-top: -4vw;
  }
  .rightCircles span {
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Montserrat";
    font-size: 5.6vw;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 9.25vw;
    display: block;
  }
  .marketFormLeft > p {
    color: #4b4b4b;
    text-align: center;
    font-family: "Montserrat";
    font-size: 7vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .inputTittle {
    margin-bottom: 1.3vw;
    color: #4b4b4b;
    font-family: "Montserrat";
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 500;
  }
  .marketFormLeft input {
    border-radius: 7vw;
    padding: 4.3vw 4.5vw;
    width: 100%;
    outline: none;
    border: none;
    background: #f1f6fa;
    font-size: 4vw;
    margin-bottom: 4.5vw;
  }
  .marketFormLeft .button {
    margin-top: 2.5vw;
    width: 100%;
    padding: 4.5vw 1vw;
    text-align: center;
    color: #fff;
    text-align: center;
    font-family: "Coolvetica Rg";
    font-size: 5.25vw;
    font-style: normal;
    font-weight: 400;
  }
  .agree {
    display: flex;
    align-items: center;
    margin-top: 4vw;
  }
  .marketWork {
    flex-direction: column;
  }
  .marketWorkLeft h2 {
    color: #303030;
    font-family: "Coolvetica Rg";
    font-size: 13.3vw;
    line-height: 13vw;
    margin-top: 8vw;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }
  .marketWorkLeft h2 img {
    width: 11vw;
    position: relative;
    top: 0.75vw;
    left: -3vw;
  }
  .marketWorkLeft p {
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Coolvetica Rg";
    font-size: 6.8vw;
    font-style: normal;
    font-weight: 400;
  }
  .marketWorkRight p {
    color: #fff;
    margin-top: 0.5vw;
    text-align: center;
    font-family: "Coolvetica Rg";
    font-size: 16.2vw;
    font-style: normal;
    font-weight: 400;
    width: -moz-fit-content;
    width: fit-content;
    border-right: 2px solid rgba(255, 255, 255, 0.31);
    border-bottom: none;
    padding-right: 3vw;
    margin-right: 3vw;
  }
  .marketWorkRight span {
    text-align: left;
    font-family: "Coolvetica Rg";
    font-size: 6vw;
    width: auto;
    font-style: normal;
    font-weight: 400;
    margin-top: 0.5vw;
    margin-bottom: 1vw;
  }
  .marketWorkRight {
    flex-direction: row;
    border-radius: 8vw;
    padding: 4vw;
    margin: 10vw 0;
    width: 100%;
  }
  .marketElem {
    padding: 6vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5.5vw;
    width: 100%;
    height: 60vw;
    background: radial-gradient(
        100.03% 100.02% at 48.08% 0%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(65, 65, 65, 0) 63.6%
      ),
      linear-gradient(145deg, #474e65 1.8%, #1f212b 53.75%, #282b36 87.75%);
    box-shadow: 0px 3.6036px 40px 0px rgba(53, 92, 129, 0.19);
  }
  .marketElem img {
    width: 13.5vw;
    margin-bottom: 6.5vw;
  }
  .marketElem span {
    color: rgba(212, 237, 255, 0.8);
    font-family: "Montserrat";
    font-size: 4.8vw;
    font-style: normal;
    font-weight: 400;
    margin-top: 2.5vw;
  }
  .marketElem p {
    color: #fff;
    font-family: "Coolvetica Rg";
    font-size: 7.3vw;
    font-style: normal;
    font-weight: 400;
  }
  .situationEm {
    border-radius: 7.5vw;
    background: #fff;
    padding: 5vw 5vw;
    margin-left: 0;
    width: 100%;
    box-shadow: 0px 0px 60px 0px rgba(147, 163, 194, 0.3);
  }
  .situationFlex .button {
    padding: 7.5vw 10vw;
    color: #fff;

    font-size: 4.5vw;
    margin-top: 5vw;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
  .market_body {
    padding-bottom: 14vw;
  }
  .situationEm > p {
    text-align: center;
    font-family: "Coolvetica Rg";
    font-size: 8.6vw;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 5vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 5vw;
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .situationFlex {
    flex-direction: column;
  }
  .situationFlex p {
    color: #303030;
    font-family: "Coolvetica Rg";
    font-size: 5.5vw;
    font-style: normal;
    font-weight: 400;
    margin-right: 0;
  }
  .elemsMarket {
    display: grid;
    justify-content: space-between;
    margin-top: 2vw;
    grid-column-gap: 6vw;
    grid-template-columns: 100% 100% 100% 100% 100%;
  }
  .agree p {
    color: rgba(131, 131, 172, 0.79);
    font-family: "Montserrat";
    font-size: 2.3vw;
    font-style: normal;
    font-weight: 500;
  }
  .circlesEm {
    display: flex;
    justify-content: center;
    margin: 5vw 0;
  }
  .circlesEm > div {
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    background: #c5ccdf;
    margin: 0 3vw;
  }
  .agree img {
    width: 4.3vw;
    margin-right: 2vw;
  }
  .marketFormLeft > span {
    color: rgba(131, 131, 172, 0.79);
    text-align: center;
    font-family: "Montserrat";
    font-size: 4.7vw;
    margin-top: 0.2vw;
    font-weight: 600;
    margin-bottom: 2vw;
  }
  .marketFormLeft {
    padding: 7vw;
    width: 100%;
    border-radius: 7vw;
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 60px 0px rgba(132, 145, 172, 0.39);
  }
  .circlescont img {
    width: 4.15vw;
    margin-right: 4.5vw;
  }
  .marketForm {
    flex-direction: column-reverse;
  }
  .rightMarket img {
    width: 100%;
    margin-left: 0vw;
  }
  .market_content {
    flex-direction: column-reverse;
  }
}
