.work {
  background: url(../../Pages/Landing/img/second.png);
  background-size: cover;
  background-position: center;
}
.workBody {
  width: 80vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vw 0;
}
.car {
  width: 100%;
  animation: small ease-in-out 6s infinite;
}
.workBody h3 {
  color: #fff;

  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 5.2vw;
  font-style: normal;
  font-weight: 400;

  text-transform: uppercase;
}
.firstI {
  width: 5vw;
  margin-bottom: 1vw;
}
.workBody {
  position: relative;
}
.workBody h3 span {
  background: linear-gradient(91deg, #30fd82 55.37%, #c8fd30 90.65%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bigLogo {
  position: absolute;
  width: 12vw;
  top: 32vw;
  animation: big ease-in-out 6s infinite;
}
@keyframes small {
  0% {
    scale: 1;
  }
  25% {
    scale: 0.95;
  }
  50% {
    scale: 1;
  }
  100% {
    scale: 1;
  }
}
@keyframes big {
  0% {
    scale: 1;
  }
  25% {
    scale: 1.05;
  }
  50% {
    scale: 1;
  }
  100% {
    scale: 1;
  }
}
.garant {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  margin: 0 auto;
}
.garantEm {
  width: 21.5vw;
  height: 17vw;
  padding: 1.5vw 1.5vw;
  border-radius: 1.5vw;
  background: linear-gradient(
    117deg,
    #125dce 2.61%,
    rgba(22, 76, 157, 0.62) 99.2%
  );
}
.hours {
  border-radius: 1.5vw;
  border: 2px solid #66fd30;
  padding: 2vw 3vw;
  width: 70vw;
  margin-bottom: 5vw;
}
.hours p {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Coolvetica Rg";
  font-size: 2.8vw;
  text-align: center;
  font-style: normal;
  font-weight: 400;
}
.other {
  background: linear-gradient(
    90deg,
    rgba(18, 165, 249, 0.4) -1.39%,
    rgba(194, 147, 255, 0.4) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  margin-bottom: 3vw;
  font-family: "Coolvetica Rg";
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
}
.workBody .button {
  padding: 2vw 3.3vw;
  color: #fff;
  margin-top: 4vw;
  font-family: "Montserrat";
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;
}
.minute {
  color: rgba(131, 131, 172, 0.79);
  margin-top: 1vw;
  font-family: "Montserrat";
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 600;
}
.hours span {
  color: #fff;

  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 2.8vw;
  text-align: center;
  display: block;
  font-style: normal;
  font-weight: 400;
}
.garantEm img {
  width: 3vw;
  margin-bottom: 1vw;
}
.garantEm p {
  color: #fff;

  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 1vw;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.garantEm span {
  color: #fff;
  margin-top: 1vw;
  display: block;
  font-family: "Coolvetica Rg";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
}
.gr2 {
  background: linear-gradient(
    117deg,
    #1dc1a4 2.61%,
    rgba(26, 121, 232, 0.54) 99.2%
  );
}
.gr3 {
  background: linear-gradient(
    117deg,
    #f57a7a 2.61%,
    rgba(235, 55, 130, 0.54) 99.2%
  );
}
@media screen and (max-width: 640px) {
  .workBody {
    width: 94vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14vw 0;
  }
  .bigLogo {
    position: absolute;
    width: 26vw;
    top: 67vw;
    left: 35.5vw;
    animation: big ease-in-out 6s infinite;
  }
  .firstI {
    width: 9vw;
    margin-bottom: 1vw;
  }
  .other {
    background: linear-gradient(
      90deg,
      rgba(18, 165, 249, 0.4) -1.39%,
      rgba(194, 147, 255, 0.4) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 8vw;
    font-family: "Coolvetica Rg";
    font-size: 6.1vw;
    margin-top: -5vw;
    font-style: normal;
    font-weight: 400;
  }
  .hours {
    border-radius: 6vw;
    border: 2px solid #66fd30;
    padding: 4vw 6vw;
    width: 100%;
    margin-bottom: 10vw;
  }
  .hours p {
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Coolvetica Rg";
    font-size: 6.5vw;
    text-align: center;
    font-style: normal;
    font-weight: 400;
  }
  .hours span {
    color: #fff;
    text-align: center;
    font-family: "Coolvetica Rg";
    font-size: 6.5vw;
    text-align: center;
    display: block;
    font-style: normal;
    font-weight: 400;
  }
  .workBody .button {
    padding: 6.5vw 14.3vw;
    color: #fff;
    margin-top: 3vw;
    font-family: "Montserrat";
    font-size: 3.9vw;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
  }
  .minute {
    color: rgba(131, 131, 172, 0.79);
    margin-top: 2vw;
    font-family: "Montserrat";
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 600;
  }
  .garantEm p {
    color: #fff;
    text-align: center;
    font-family: "Coolvetica Rg";
    font-size: 9.2vw;
    font-style: normal;
    font-weight: 400;
    padding-bottom: 1vw;
    text-align: left;
    border-bottom: none;
  }
  .garantEm {
    background: none;
    border-radius: unset;
    width: 100%;
    padding: 8vw;
    padding-top: 0;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .garantEm span {
    color: #fff;
    margin-top: 1vw;
    display: block;
    font-family: "Coolvetica Rg";
    font-size: 5.25vw;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    padding-bottom: 8vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }
  .garantEm:last-child span:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .garantEm img {
    display: none;
  }
  .garant {
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-direction: column;
    padding-top: 8vw;
    padding-bottom: 0;
    border-radius: 7vw;
    overflow: hidden;

    background: linear-gradient(117deg, #2c2c56 2.61%, #1d1d36 99.2%);
    margin-bottom: 8vw;
  }
  .workBody h3 {
    color: #fff;
    text-align: center;
    font-family: "Coolvetica Rg";
    font-size: 10.2vw;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
  }
}
