@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Montserrat:wght@300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  transition: 0.5s;
  box-sizing: border-box;
}
/*font-family: 'Inter', sans-serif;
font-family: 'Montserrat', sans-serif;*/
.App {
  overflow-x: hidden;
}
