.footerBody {
  width: 80vw;
  margin: 0vw auto;
  padding: 4vw 0;
}
.footer {
  background: #f2f4f8;
}
.firstFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.firstFooter > img {
  width: 12vw;
}
.telHref {
  color: #3e2e2e;

  font-family: "Montserrat";
  font-size: 1.65vw;
  font-style: normal;
  font-weight: 700;
}
.types {
  display: flex;
  align-items: center;
  color: #3e2e2e;
  text-transform: lowercase;
  font-family: "Montserrat";
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
}
.footerBody a {
  color: #3e2e2e;
  text-decoration: none;
}
.types img {
  width: 2.5vw;
  margin-right: 1vw;
}
.secondFooter {
  display: flex;
  align-items: center;
  color: #3e2e2e;
  justify-content: flex-start;
  font-family: "Montserrat";
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 500;
  margin-top: 2vw;
}
.politics {
  margin-left: 30vw;
}
.grayLine {
  width: 5vw;
  height: 2px;
  background: rgba(120, 92, 229, 0.28);
  margin: 0 1.5vw;
}
.ofert {
  float: right;
  font-size: 0.7vw;
  opacity: 0.4;
  margin-top: 1.5vw;
}
@media (max-width: 640px) {
  .footerBody {
    width: 94vw;
    margin: 0vw auto;
    padding: 10vw 0;
  }
  .firstFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .firstFooter > img {
    width: 47vw;
  }
  .types {
    display: flex;
    align-items: center;
    color: #3e2e2e;
    text-transform: lowercase;
    font-family: "Montserrat";
    font-size: 5.3vw;
    font-style: normal;
    font-weight: 500;
    margin-top: 8vw;
  }
  .types img {
    width: 7.5vw;
    margin-right: 2vw;
  }
  .telHref {
    color: #3e2e2e;
    font-family: "Montserrat";
    font-size: 5vw;
    font-style: normal;
    font-weight: 500;
  }
  .secondFooter {
    display: flex;
    align-items: center;
    color: #3e2e2e;
    justify-content: flex-start;
    font-family: "Montserrat";
    flex-direction: column;
    margin-top: 5vw;
    font-size: 3.8vw;
    font-style: normal;
    width: 80vw;
    margin: 0 auto;
    font-weight: 500;
    margin-top: 8vw;
  }
  .secondFooter * {
    text-align: center;
  }
  .grayLine {
    width: 100%;
    height: 2px;
    background: rgba(120, 92, 229, 0.28);
    margin: 6vw 1.5vw;
  }
  .politics {
    margin: 0;
    margin-top: 5vw;
  }
  .ofert {
    float: none;
    margin-top: 10vw;
    font-size: 2.7vw;
    text-align: center;
  }
}
