.smartContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.smart {
  padding: 3vw 0;
  background: url(./img/bg.png);
  background-size: cover;
  background-position: center;
}
.smartBody {
  width: 80vw;
  margin: 0 auto;
}
.smartBody .button {
  padding: 2vw 5vw;
  margin-top: 2vw;
  color: #fff;
  font-family: "Montserrat";
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
}
.smartRight {
  width: fit-content;
}
.smartBody h3 {
  font-family: "Coolvetica Rg";
  font-size: 7.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 7vw;
  text-transform: uppercase;
  background: linear-gradient(90deg, #30fd82 0.05%, #c8fd30 67.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.smartBody h2 {
  font-family: "Coolvetica Rg";
  font-size: 4.5vw;
  font-style: normal;
  font-weight: 400;
  background: linear-gradient(
    180deg,
    rgba(159, 159, 169, 0.13) 1.04%,
    rgba(159, 159, 169, 0.69) 20.31%,
    #fff 60.42%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.smartBody li:last-child {
  border-bottom: none;
}
.smartBody li div {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Inter";
  font-size: 4vw;
  margin-right: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 3.5vw;
  text-align: center;
}
.smartBody li p {
  color: #fff;

  font-family: "Montserrat";
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.smartBody li {
  padding: 0.5vw 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.smartBody > p {
  font-family: "Inter";
  font-size: 2.4vw;
  margin-top: 0.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5vw;
}
.smartLeft {
  width: 44vw;
}
.girl {
  width: 38vw;
}
.smartRight {
  width: 34vw;
  position: relative;
}
.phone {
  position: absolute;
  left: 0;
  top: 0;
  animation: phone 3s infinite;
  width: 19vw;
}
.obesh {
  color: rgba(131, 131, 172, 0.79);
  text-align: center;
  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  margin-top: 1vw;
  margin-bottom: 2.5vw;
  display: block;
  margin-left: -22vw;
}
@keyframes phone {
  0% {
    translate: 0 0;
  }
  50% {
    translate: 0 -10px;
  }
  100% {
    translate: 0 0;
  }
}
@media screen and (max-width: 640px) {
  .smartContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
  }
  .smartBody {
    width: 94vw;
  }
  .smartBody h3 {
    font-family: "Coolvetica Rg";
    font-size: 14vw;
    font-style: normal;
    font-weight: 400;
    line-height: 14vw;
    text-transform: uppercase;
    background: linear-gradient(90deg, #30fd82 0.05%, #c8fd30 67.02%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .smartBody h2 {
    font-family: "Coolvetica Rg";
    font-size: 6.7vw;
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(
      180deg,
      rgba(159, 159, 169, 0.13) 1.04%,
      rgba(159, 159, 169, 0.69) 20.31%,
      #fff 60.42%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .smartBody > p {
    font-family: "Inter";
    font-size: 4.1vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 8vw;
    margin-top: 3vw;
  }
  .smartLeft {
    width: 100%;
  }
  .smartBody li {
    padding: 2.5vw 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .phone {
    position: absolute;
    left: -1vw;
    width: 50vw;
    top: -1vw;
    animation: phone 3s infinite;
  }
  .girl {
    width: 100%;
  }
  .smartRight {
    width: 100%;
    position: relative;
    margin-top: 14vw;
  }
  .obesh {
    color: rgba(131, 131, 172, 0.79);
    text-align: center;
    font-family: "Montserrat";
    font-size: 3.7vw;
    font-style: normal;
    font-weight: 600;
    margin-top: 2vw;
    display: block;
    margin-left: 0;
  }
  .smartBody .button {
    padding: 6.5vw 5vw;
    margin-top: 3vw;
    color: #fff;
    font-family: "Montserrat";
    font-size: 5.05vw;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 600;
  }
  .smartBody li p {
    color: #fff;
    font-family: "Montserrat";
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .smartBody li div {
    background: linear-gradient(90deg, #12a5f9 -1.39%, #c293ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Inter";
    font-size: 7.6vw;
    margin-right: 1vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 6.5vw;
    text-align: center;
  }
  .smart {
    padding: 9vw 0;
    background: #191932;
  }
}
