.posad .headerTop {
  background: #ece9f3;
}
.posad .dostavka {
  color: black;
}
.posad .button_header {
  background: linear-gradient(
      104deg,
      rgba(255, 255, 255, 0.1) 45.38%,
      rgba(255, 255, 255, 0) 45.45%
    ),
    linear-gradient(89deg, #9462ff 14.97%, #580aff 99.48%);
  box-shadow: 0px 0px 27px 0px rgba(212, 80, 244, 0.25);
}
.posad .askMe {
  color: black;
}
.posad .headerTop a {
  color: #5b5b6a;
}
.posadHeadCont {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10vw;
  background: #f2f4f8;
  padding-top: 5vw;
}
.zach {
  border: 3.154px solid #8ee000;
  border-radius: 100px;
  padding: 0.5vw 1vw;
  color: #5b5b6a;
  width: fit-content;
  font-family: "Montserrat";
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 1vw;
  box-shadow: 0px 5.1362px 15.40859px 0px #88c779;
}
.posadHeadCont h2 {
  color: #5b5b6a;

  font-family: "Coolvetica Rg";
  font-size: 5.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 103%; /* 89.85px */
  text-transform: uppercase;
}
.posadHeadCont h2 div {
  background: linear-gradient(89deg, #9f72ff 0.54%, #7230fd 99.48%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pr {
  color: #5b5b6a;
  font-family: "Coolvetica Rg";
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
  background: #fff;
  width: fit-content;
  padding: 0.5vw 1.5vw;
  margin-top: 1vw;
  border-radius: 100px;
  box-shadow: 0px 5.1362px 15.40859px 0px #ced8ec;
  text-transform: uppercase;
}
.pr span {
  font-size: 1.5vw;
}
.pus {
  display: flex;
  margin-top: 3vw;
}
.pusEm:last-child {
  border-right: none;
}
.pusEm {
  display: flex;
  align-items: center;
  margin-right: 1.25vw;
  padding-right: 1.25vw;
  border-right: 1px solid #dad3e8;
}
.pusEm img {
  width: 2.5vw;
  margin-right: 0.8vw;
}
.pusEm p {
  background: linear-gradient(89deg, #9f72ff 0.54%, #7230fd 99.48%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Coolvetica Rg";
  font-size: 1.5vw;
  text-transform: uppercase;
}
.btnP {
  display: flex;
  align-items: center;
}
.pusEm span {
  font-size: 1.2vw;
  font-weight: 300;
  font-family: "Coolvetica Lt";
  display: flex;
}

.btnP {
  margin-top: 3vw;
  margin-bottom: 6vw;
}
.btnP .button {
  font-size: 1.4vw;
  font-family: "Montserrat";
  text-transform: uppercase;
  padding: 1.7vw 5vw;
  border-radius: 1000px;
  background: linear-gradient(
      104deg,
      rgba(255, 255, 255, 0.1) 45.38%,
      rgba(255, 255, 255, 0) 45.45%
    ),
    linear-gradient(89deg, #9462ff 14.97%, #580aff 99.48%);
  box-shadow: 0px 0px 35.39623px 0px rgba(53, 6, 239, 0.25),
    0px 0px 17.69812px 0px rgba(111, 74, 255, 0.28);
  margin-left: 2vw;
}
.koks p {
  color: #5b5b6a;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 1.5vw;
}
.koks div {
  color: #fff;
  margin-top: 0.3vw;
  text-align: center;
  padding: 0.3vw 1vw;
  width: fit-content;
  border-radius: 30.087px;
  background: linear-gradient(90deg, #41cc00 -1.33%, #8ada00 104.78%);
  font-family: "Montserrat";
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 700;
}
.posadHeadRightCont img {
  width: 33vw;
  position: relative;
  top: 0.1vw;
  z-index: 3;
  left: 4.5vw;
}
.posadHeadRightCont {
  position: relative;
  right: 4vw;
}
.posad .smart {
  background: url(./img/port.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 6vw;
}
.posadHeadCont {
  position: relative;
}
.pusik {
  position: absolute;
  left: 0;
  width: 100vw;
  pointer-events: none;
  animation: pusik 2s ease-in infinite;
}
.posad .smartBody .button {
  border-radius: 1000px;
  background: linear-gradient(
      104deg,
      rgba(255, 255, 255, 0.15) 45.38%,
      rgba(255, 255, 255, 0) 45.45%
    ),
    linear-gradient(
      95deg,
      #9dfe3b -9.57%,
      #7fff00 -9.56%,
      #4fc705 59.56%,
      #4fc705 72.14%
    );
  box-shadow: 0px 0px 50px 0px rgba(40, 125, 0, 0.28),
    0px 0px 30px 0px rgba(143, 255, 0, 0.43);
}
.downs {
  display: block;
  margin-top: -3.4vw;
  z-index: 2;
  position: relative;
  width: 100vw;
}
@keyframes pusik {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
@media (max-width: 640px) {
  .posadHeadCont {
    flex-direction: column;
    padding: 4vw;
  }
  .posad .smart {
    background: url(./img/grad.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 33vw;
  }
  .posadHeadLeftCont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .zach {
    border: 3.154px solid #8ee000;
    border-radius: 100px;
    padding: 1.5vw 4vw;
    color: #5b5b6a;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "Montserrat";
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 8vw;
    box-shadow: 0px 5.1362px 15.40859px 0px #88c779;
  }
  .posadHeadLeftCont h2 {
    text-align: center;
  }
  .posadHeadCont h2 {
    color: #5b5b6a;
    font-family: "Coolvetica Rg";
    font-size: 11vw;
    font-style: normal;
    font-weight: 400;
    line-height: 103%;
    text-transform: uppercase;
  }
  .pr {
    background: #5b5b6a;
    color: #fff;
    font-family: "Coolvetica Rg";
    font-size: 6.8vw;
    font-style: normal;
    font-weight: 400;

    width: -moz-fit-content;
    width: fit-content;
    padding: 1.5vw 3.5vw;
    margin-top: 3vw;
    border-radius: 100px;
    box-shadow: 0px 5.1362px 15.40859px 0px #ced8ec;
    text-transform: uppercase;
  }
  .pr span {
    font-size: 4.2vw;
  }
  .posadHeadRightCont img {
    width: 94vw;
    position: relative;
    top: 0.2vw;
    left: 0;
    z-index: 3;
  }
  .posadHeadRightCont {
    margin-top: 7vw;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .koks {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .koks p {
    color: #5b5b6a;
    font-weight: 600;
    font-family: "Montserrat";
    font-size: 6.2vw;
  }
  .koks div {
    color: #fff;
    margin-top: 2.3vw;
    text-align: center;
    text-transform: lowercase;
    padding: 1.3vw 4vw;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 30.087px;
    background: linear-gradient(90deg, #41cc00 -1.33%, #8ada00 104.78%);
    font-family: "Montserrat";
    font-size: 6.3vw;
    font-style: normal;
    font-weight: 700;
  }
  .btnP .button {
    font-size: 6.1vw;
    font-family: "Montserrat";
    text-transform: uppercase;
    padding: 6.5vw 20vw;
    margin-top: 5vw;
    border-radius: 1000px;
    background: linear-gradient(
        104deg,
        rgba(255, 255, 255, 0.1) 45.38%,
        rgba(255, 255, 255, 0) 45.45%
      ),
      linear-gradient(89deg, #9462ff 14.97%, #580aff 99.48%);
    box-shadow: 0px 0px 35.39623px 0px rgba(53, 6, 239, 0.25),
      0px 0px 17.69812px 0px rgba(111, 74, 255, 0.28);
    margin-left: 1.5vw;
    margin-bottom: 15vw;
  }
  .btnP {
    flex-direction: column;
  }
}
