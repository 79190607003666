.prs {
  background: linear-gradient(89deg, #80ca00 0.54%, #1bbf00 99.48%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Coolvetica Rg";
  font-size: 2vw;
  text-transform: uppercase;
}
.ths {
  color: #5b5b6a;

  font-family: "Montserrat";
  font-size: 1.5vw;
  width: 32vw;
  font-style: normal;
  font-weight: 400;
  margin-top: 1vw;
}
.changeColor .footer {
  background: #ece9f3;
}
.changeColor .footerBody {
  padding: 2vw 0;
  padding-bottom: 4vw;
}
.obeshf {
}
.ole .button {
  margin: 0 !important;
}
.ole span {
  color: #a38fbc;

  text-align: center;
  font-family: "Montserrat";
  font-size: 0.8vw;
  margin-left: 5vw;
  margin-top: 0.7vw;
  font-style: normal;
  font-weight: 600;
}
.button {
  text-decoration: none;
  display: block;
}
.thankss .posadHeadRightCont img {
  width: 40vw;
  position: relative;
  display: block;
  margin-top: -4vw;
  left: 0vw;
}
@media (max-width: 640px) {
  .prs {
    background: linear-gradient(89deg, #80ca00 0.54%, #1bbf00 99.48%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Coolvetica Rg";
    font-size: 6.6vw;
    margin-top: 5vw;
    text-transform: uppercase;
  }
  .thankss .posadHeadRightCont img {
    width: 100%;
    position: relative;
    display: block;
    margin-top: 0;
    left: 0vw;
  }
  .ths {
    color: #5b5b6a;
    font-family: "Montserrat";
    font-size: 5.2vw;
    text-align: center;
    width: 93%;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10vw;
    margin-top: 1vw;
  }
}
