.popForm {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2222;
  background: rgba(0, 0, 0, 0.5);
}
.popFormBody {
  display: flex;
  flex-direction: column;
  background: url(./form.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #fff;
  padding: 2vw;
  width: 25vw;
  border-radius: 3vw;
}
.popFormBody input {
  outline: none;
  border-radius: 3vw;
  background: #eee;
  padding: 1.5vw;
  font-size: 1vw;
  border: none;
  margin-top: 2vw;
}
.sendFrom {
  background: linear-gradient(
      104deg,
      rgba(255, 255, 255, 0.1) 45.38%,
      rgba(255, 255, 255, 0) 45.45%
    ),
    linear-gradient(89deg, #3ca407 0.54%, #84d901 99.48%);
  filter: drop-shadow(0px 0px 40px rgba(109, 239, 6, 0.25))
    drop-shadow(0px 0px 20px rgba(74, 255, 82, 0.28));

  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 1.5vw;
  border-radius: 3vw;
  margin-top: 2vw;
  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  position: relative;
  overflow-x: hidden;
}
.svz {
  text-align: center;
  color: #fff;

  text-align: center;
  font-family: "Montserrat";
  font-size: 1vw;
  margin-top: 1vw;
  margin-bottom: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.formZ {
  color: #fff;
  font-family: "Coolvetica Rg";
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 2.8vw;
  letter-spacing: 1.048px;
  text-transform: uppercase;
}
.formZ span {
  color: #806bff;
}
@media (max-width: 640px) {
  .popFormBody {
    width: 90vw;
    padding: 5vw;

    border-radius: 8vw;
  }
  .formZ {
    color: #fff;
    font-family: "Coolvetica Rg";
    font-size: 11.7vw;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: 11.8vw;
    letter-spacing: 1.048px;
    text-transform: uppercase;
  }
  .svz {
    text-align: center;
    color: #fff;
    text-align: center;
    font-family: "Montserrat";
    font-size: 4vw;
    margin-top: 3vw;
    margin-bottom: 6vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .popFormBody input {
    outline: none;
    border-radius: 8vw;
    background: #eee;
    padding: 4.5vw;
    font-size: 5vw;
    border: none;
    margin-top: 6vw;
  }
  .sendFrom {
    background: linear-gradient(
        104deg,
        rgba(255, 255, 255, 0.1) 45.38%,
        rgba(255, 255, 255, 0) 45.45%
      ),
      linear-gradient(89deg, #3ca407 0.54%, #84d901 99.48%);
    filter: drop-shadow(0px 0px 40px rgba(109, 239, 6, 0.25))
      drop-shadow(0px 0px 20px rgba(74, 255, 82, 0.28));
    color: #fff;
    text-align: center;
    padding: 5.5vw;
    border-radius: 8vw;
    margin-top: 7vw;
    font-family: "Montserrat";
    font-size: 4vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.3px;
    text-transform: uppercase;
    margin-bottom: 3vw;
  }
}
