.headerTop {
  display: flex;
  align-items: center;
  width: 100vw;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  padding: 1.3vw 10vw;
  background: #191932;
}
.headerTop a {
  color: #acacea;
  font-family: "Montserrat";
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  margin-left: 1vw;
}
.logo {
  width: 11vw;
  margin-right: 6vw;
}
.dostavka {
  color: #fff;
  font-family: "Montserrat";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 3vw;
}
.askMe {
  color: #fff;
  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.header {
  background: #100831;
}
.headerCont h1 {
  color: #fff;
  margin-top: 1.5vw;
  font-family: "Coolvetica Rg";
  font-size: 8vw;

  font-style: normal;
  font-weight: 400;
  line-height: 6vw;
}
.headerCont h2 {
  font-family: "Inter";
  font-size: 3.3vw;

  font-weight: 700;
  line-height: normal;
  text-transform: lowercase;
  background: linear-gradient(90deg, #12a5f9 -1.39%, #e087ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.start {
  background: rgba(27, 27, 52, 0.5);
  border-radius: 3vw;
  padding: 0.5vw 1.5vw;
  color: #a0a0d4;

  font-family: "Coolvetica Rg";
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
  width: fit-content;
  margin-top: 2vw;
}
.firstGr {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #e087ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: 700;
}
.arrow {
  position: absolute;
  top: 24.5vw;
  left: 27vw;
  width: 5vw;
}
.SecondGr {
  background: linear-gradient(90deg, #12a5f9 -1.39%, #e087ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Montserrat";

  font-weight: 600;
}
.headerLeftContent > p {
  color: #fff;
  font-family: "Montserrat";
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  margin-top: 3.3vw;
}
.start span {
  color: #fff;

  font-size: 3.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 94.5%;
}
.headerCont h1 span {
  font-size: 4.5vw;
  font-size: 6vw;

  background: linear-gradient(
    180deg,
    rgba(159, 159, 169, 0.13) 1.04%,
    rgba(159, 159, 169, 0.69) 20.31%,
    #fff 60.42%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.headerCont {
  width: 100vw;
  padding: 0 10vw;
  padding-top: 2vw;

  justify-content: space-between;
  position: relative;
}
.lamp {
  position: absolute;
  top: 3.5vw;
  left: 32.5vw;
  width: 13vw;
}
.rightCont img {
  width: 37vw;
}
.rightCont {
  position: relative;
  bottom: 0vw;
  right: 0vw;
}
.buttonContent {
  display: flex;
  flex-direction: column;
  margin-left: 1.5vw;
  margin-top: 1.5vw;
}
.buttonContent p {
  color: #b3b3d8;
  font-family: "Montserrat";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 0.2vw;
}
.buttonContent img {
  width: 1.2vw;
  margin-left: 0.5vw;
}
.buttonContent span {
  color: #fff;
  font-family: "Montserrat";
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
.ach {
  position: absolute;
  right: 2vw;
  top: 2vw;
  animation: ach 5s infinite;
}
@keyframes ach {
  0% {
    scale: 1;
  }
  25% {
    scale: 1.05;
  }
  50% {
    scale: 1;
  }
  100% {
    scale: 1;
  }
}
.button {
  background: #3506ef;
  color: #fff;
  box-shadow: 0px 0px 27px 0px rgba(53, 6, 239, 0.25),
    0px 0px 10px 0px rgba(98, 63, 239, 0.36);
  font-family: "Coolvetica Rg";
  font-weight: 600;
  border-radius: 5vw;
  width: fit-content;
  cursor: pointer;
}
.button:hover {
  background: #7656f6;
}
.button_header {
  padding: 0.7vw 2vw;
  margin-right: 4.4vw;
}
.icon {
  width: 2.5vw;
  margin: 0 0.5vw;
}

.buttonAndText p {
  text-align: center;
  margin-top: 0.8vw;
  color: rgba(131, 131, 172, 0.79);
  font-family: "Montserrat";
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 600;
}
.buttonStructure {
  margin-top: 3.8vw;
  display: flex;
}
.buttonMain {
  color: #fff;
  font-family: "Montserrat";
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  padding: 2vw 2.5vw;
  text-transform: uppercase;
}
.headerCont {
  display: flex;
  background: url(./desc.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (max-width: 640px) {
  .headerCont {
    width: 100vw;
    padding: 0 3vw;
    flex-direction: column;
    background: url(./img/mob.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .headerCont h1 {
    color: #fff;
    margin-top: 11vw;
    font-family: "Coolvetica Rg";
    font-size: 18.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 15vw;
  }
  .headerCont h2 {
    font-family: "Inter";
    font-size: 8.5vw;
    font-weight: 700;
    line-height: normal;
    text-transform: lowercase;
    background: linear-gradient(90deg, #12a5f9 -1.39%, #e087ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .start span {
    color: #fff;
    font-size: 7.1vw;
    font-style: normal;
    font-weight: 400;
    line-height: 94.5%;
  }
  .start {
    background: rgba(27, 27, 52, 0.5);
    border-radius: 10vw;
    padding: 2.5vw 4.5vw;
    color: #a0a0d4;
    font-family: "Coolvetica Rg";
    font-size: 4.6vw;
    font-style: normal;
    font-weight: 400;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 4vw;
  }
  .headerLeftContent > p {
    color: #fff;
    font-family: "Montserrat";
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 500;
    margin-top: 5.3vw;
  }
  .rightCont img {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-right: 2vw;
  }
  .rightCont {
    right: 0;
  }
  .ach {
    position: absolute;
    right: 9vw;
    top: 11vw;
    animation: ach 5s infinite;
  }
  .buttonMain {
    color: #fff;
    font-family: "Montserrat";
    font-size: 5vw;
    font-style: normal;
    font-weight: 600;
    padding: 6vw 9.2vw;
    text-transform: uppercase;
  }
  .button {
    background: #3506ef;
    color: #fff;
    box-shadow: 0px 0px 27px 0px rgba(53, 6, 239, 0.25),
      0px 0px 10px 0px rgba(98, 63, 239, 0.36);
    font-family: "Coolvetica Rg";
    font-weight: 600;
    border-radius: 15vw;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
  }
  .buttonStructure {
    justify-content: center;
    margin-top: 8vw;
    padding-bottom: 8vw;
  }
  .buttonAndText p {
    text-align: center;
    margin-top: 2.5vw;
    color: rgba(131, 131, 172, 0.79);
    font-family: "Montserrat";
    font-size: 2.8vw;
    font-style: normal;
    font-weight: 600;
  }
  .rightCont {
    margin-top: 8vw;
  }
  .headerCont h1 span {
    font-size: 15.1vw;
    background: linear-gradient(
      180deg,
      rgba(159, 159, 169, 0.13) 1.04%,
      rgba(159, 159, 169, 0.69) 20.31%,
      #fff 60.42%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .lamp {
    position: absolute;
    top: 10vw;
    left: 52vw;
    width: 34vw;
  }
  .headerTop {
    padding: 5vw 3vw;
    width: 100vw;
  }
  .dostavka {
    display: none;
  }
  .headerTop a {
    color: #acacea;
    font-family: "Montserrat";
    font-size: 4.3vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    margin-left: 1vw;
  }
  .icon {
    width: 7vw;
    margin: 0 1.5vw;
  }
  .button_header {
    display: none !important;
  }
  .askMe {
    display: none;
  }
  .logo {
    width: 29.4vw;
    margin-right: 4.3vw;
  }
  .arrow {
    position: absolute;
    top: 61.5vw;
    left: 39vw;
    width: 13vw;
  }
}
